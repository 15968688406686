import { SEARCH_TEXT } from "../Actions/actions";


const initialState = {
    search: []
};


export const SearchReducer = (state: any = initialState, action: any) => {

    switch (action.type) {
    case SEARCH_TEXT:
        return {
            ...state,
            search: action.payload
        };
    default:
        return state;
    }
};
