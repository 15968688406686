import { PRODUCT_SERIAL_DETAILS } from "redux/Actions/actions";

const initialState = {
    productSerialData: []
};

export const ProductSerialReducer = (state: any = initialState, action: any) => {

    switch (action.type) {
    case PRODUCT_SERIAL_DETAILS:
        return {
            ...state,
            productSerialData: action.payload
        };
    default:
        return state;
    }
};