import { ADD_INVENTORY } from "../Actions/actions";


const initialState = {
    inventoryData: []
};


export const InventoryReducer = (state: any = initialState, action: any) => {

    switch (action.type) {
    case ADD_INVENTORY:
        return {
            ...state,
            inventoryData: action.payload
        };
    default:
        return state;
    }
};
