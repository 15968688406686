import { WAREHOUSE_DETAILS } from "../Actions/actions";


const initialState = {
    warehouseData: []
};


export const WarehouseReducer = (state: any = initialState, action: any) => {

    switch (action.type) {
    case WAREHOUSE_DETAILS:
        return {
            ...state,
            warehouseData: action.payload
        };
    default:
        return state;
    }
};
